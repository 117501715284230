/* --------------- Home Page Css ------------------ */
/* home slider */
.home-banner {
  width: 100%;
  min-height: 500px;
  position: relative;
  padding: 140px 0 50px;
  display: flex;
  align-items: center;
}
.home-banner > .banner-background-img{
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.home-banner .banner-content {
  text-align: center;
  padding-right: 80px;
}

.home-banner .banner-content h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #FBC42A;
  margin-bottom: 10px;
}

.home-banner .banner-content p {
  color: #FFFFFF;
}

.home-banner .slider-image {
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin: auto;
}

.home-banner .slider-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative !important;
}

.home-banner .slider-searchbar .input-group .select2 .select2-selection {
  border: 0;
}

.home-banner .slider-searchbar .input-group .select2 .select2-selection .select2-selection__rendered {
  text-align: left;
  padding-left: 40px;
  color: #BDBDBD;
  font-weight: 500;
  font-size: 16px;
}

.home-banner .slider-searchbar .input-group .select2 .select2-selection .select2-selection__arrow {
  display: none;
}

.slider-searchbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  background: #FFFFFF;
  border: 2px solid #FFD7A0;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 100px;
  width: 80%;
  margin: auto;
}
@media (max-width:568px) {
  .slider-searchbar {
    position: relative;
  }
}

.slider-searchbar .input-group {
  position: relative;
  display: block;
  width: 100%;
  border-right: 2px solid #CCD4CF;
  padding-right: 10px;
  margin-right: 10px;
}

.slider-searchbar .form-control::placeholder {
  color: #BDBDBD;
}

.slider-searchbar .form-control:-ms-input-placeholder {
  color: #BDBDBD;
}

.slider-searchbar .form-control::-ms-input-placeholder {
  color: #BDBDBD;
}
.slider-searchbar .form-control {
  padding-left: 40px;
  border: 0;
  margin: 0 !important;
}

.slider-searchbar .input-group:last-child {
  border: 0;
}

.slider-searchbar .input-group .input-group-icon {
  background: transparent;
  border: 0;
  position: absolute;
  z-index: 99;
  left: 14px;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-searchbar .input-group .input-group-icon i {
  color: #999999;
  font-size: 16px;
  font-weight: 500;
}

.slider-searchbar .input-group .form-select {
  border: 0;
  background-color: transparent;
  padding: 0;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  border: 0 !important;
  padding: 10px 10px 10px 45px;
  width: 100%;
}

.slider-searchbar .input-group .form-select .select2-container .select2-selection--single {
  height: auto !important;
}

.slider-searchbar .input-group .form-select::placeholder {
  color: #BDBDBD;
}

.slider-searchbar .input-group .form-select:focus {
  box-shadow: none;
  border: 0;
}

.css-1jqq78o-placeholder {
  color: #BDBDBD !important;
}

/* welcome healing */
.welcome-healing {
  padding: 80px 0;
}

.welcome-healing::after {
  content: "";
  background-image: url(/images/icon/hand.svg);
  width: 120px;
  height: 120px;
  position: absolute;
  left: 10px;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-animation: moveUp 1.5s infinite alternate;
  animation: moveUp 1.5s infinite alternate;
}

.welcome-healing .section-title {
  margin-bottom: 40px;
}

.welcome-healing .tabing-welcome {
  text-align: center;
  margin-bottom: 50px;
}

.welcome-healing .tabing-welcome .nav-tabs {
  border: 0;
  justify-content: center;
  border-radius: 100px;
  background: #F3F6F7;
  display: inline-flex;
  padding: 6px;
  overflow: hidden;
}

.welcome-healing .tabing-welcome .nav-tabs .nav-link {
  border: 0;
  background: transparent;
  color: #5D676E;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 8px 35px;
  border-radius: 100px;
  width: 220px;
}

.welcome-healing .tabing-welcome .nav-tabs .nav-link img {
  width: 22px;
  height: 22px;
  object-fit: contain;
  filter: contrast(10) brightness(0);
  transition: all 0.3s ease;
}

.welcome-healing .tabing-welcome .nav-tabs .nav-link.active {
  background-color: #002A0F;
  color: #fff;
}

.welcome-healing .tabing-welcome .nav-tabs .nav-link.active img {
  filter: contrast(0) brightness(10);
}

.welcome-healing .tabcontent-welcome .inner-content {
  position: relative;
}

.welcome-healing .tabcontent-welcome .inner-content::before {
  content: "";
  background-image: url(/images/shape-welcome.svg);
  width: 130px;
  height: 130px;
  z-index: -1;
  position: absolute;
  top: -75px;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.welcome-healing .tabcontent-welcome .inner-content h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  color: #002A0F;
  margin-bottom: 15px;
}

.welcome-healing .tabcontent-welcome .inner-content p {
  margin-bottom: 15px;
  line-height: 2.5;
  font-size: 16px;
}

.welcome-healing .tabcontent-welcome .inner-content h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #002A0F;
  margin-bottom: 20px;
}

ul.HelpSolveIcon {
  column-count: 2;
  margin: 0;
  padding: 0;
}

ul.HelpSolveIcon li {
  position: relative;
  margin-bottom: 30px;
  color: #333;
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  display: block;
}

ul.HelpSolveIcon li {
  display: inline-flex;
  align-items: center;
  column-gap: 20px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 100%;
}

ul.HelpSolveIcon li .iconImg {
  width: 65px;
  height: 65px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #CED0DD;
  border-radius: 100%;
}

ul.HelpSolveIcon li .iconImg img {
  width: 100%;
  height: 100%;
}

ul.HelpSolveIcon li span {
  width: calc(100% - 70px);
}

ul.HelpSolveIcon li img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  left: 0;
}

.welcome-healing .tabcontent-welcome .welecome-tab-image {
  width: 100%;
  height: 100%;
  padding-left: 80px;
}

.welcome-healing .tabcontent-welcome .welecome-tab-image img {
  width: 100% !important;
  height: auto;
}

.bringfor-your {
  padding: 100px 0;
  background-color: #2C4C76;
}

.bringfor-your::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 0;
  background-image: url(/images/yoga-2.svg);
  background-position: center;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  z-index: -1;
}

.bringfor-your .section-title .title {
  color: #FBC42A;
}

.bringfor-your .section-title p {
  margin-top: 20px;
  color: #fff;
}

.bringfor-your .tabing-customer {
  text-align: center;
  margin-bottom: 50px;
}

.bringfor-your .tabing-customer .nav-tabs {
  border: 0;
  justify-content: center;
  border-radius: 100px;
  background: #F3F6F7;
  display: inline-flex;
  padding: 6px;
  overflow: hidden;
}

.bringfor-your .tabing-customer .nav-tabs .nav-link {
  border: 0;
  background: transparent;
  color: #5D676E;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 8px 50px;
  border-radius: 100px;
  width: 220px;
}

.bringfor-your .tabing-customer .nav-tabs .nav-link img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  filter: contrast(10) brightness(0);
  transition: all 0.3s ease;
}

.bringfor-your .tabing-customer .nav-tabs .nav-link.active {
  background-color: #002A0F;
  color: #fff;
}

.bringfor-your .tabing-customer .nav-tabs .nav-link.active img {
  filter: contrast(0) brightness(10);
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .icon {
  background: #F3F6F7;
  border-radius: 15px;
  padding: 15px;
  width: 75px;
  height: 75px;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .icon img {
  width: 100%;
  height: 100%;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content {
  width: calc(100% - 80px);
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content p {
  font-weight: 600;
  font-size: 16px;
  line-height: 2;
  color: #fff;
  margin: 0;
}

.bringfor-your .tabcontent-customer .forcustomer-content .middle-image {
  margin: 20px 0;
  background: #FFFBF7;
  border-radius: 40px;
  width: 100%;
  height: 300px;
  padding: 25px 25px;
}

.bringfor-your .tabcontent-customer .forcustomer-content .middle-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-bottom {
  text-align: center;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-bottom h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #FBC42A;
  margin-bottom: 10px;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-bottom p {
  color: #fff;
}


/* choose help */
/* .choose-help {
  padding: 100px 0;
}

.choose-help .counselling-therapy {
  background: #FDF8F4;
}

.choose-help .counselling-therapy .inner-content {
  padding: 60px 40px 60px 100px;
  height: 100%;
}

.choose-help .counselling-therapy .inner-content h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 10px;
}

.choose-help .counselling-therapy .inner-content p {
  color: #333333;
  line-height: 2.3;
  margin-bottom: 10px;
}

.choose-help .counselling-therapy .inner-content ul {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 15px;
  margin: 15px 0 40px;
  padding: 0;
}

.choose-help .counselling-therapy .inner-content ul li {
  width: 30%;
  flex-grow: 1;
  text-align: center;
}

.choose-help .counselling-therapy .inner-content ul li .icon {
  width: 80px;
  height: 80px;
  background: #FFECD8;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 0 auto 10px;
}

.choose-help .counselling-therapy .inner-content ul li .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.choose-help .counselling-therapy .inner-content ul li h6 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  line-height: 24px;
}

.choose-help .our-doctor {
  padding: 30px 0 10px 30px;
  background-color: #fefefe;
}

.doctor-card {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.doctor-card .doctor-image {
  width: 100%;
  height: 200px;
}

.doctor-card .doctor-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.doctor-card .doctor-content {
  padding: 20px 15px;
}

.doctor-card .doctor-content .doctor-name {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 6px;
}

.doctor-card .doctor-content .doctor-name h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #002A0F;
}

.doctor-card .doctor-content .doctor-name i {
  color: #1ABC9C;
}

.doctor-card .doctor-content h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #767676;
}

.doctor-card .doctor-content .feedback {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.doctor-card .doctor-content .feedback ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  margin: 0;
  padding: 0;
}

.doctor-card .doctor-content .feedback ul li {
  line-height: 1;
}

.doctor-card .doctor-content .feedback ul li i {
  color: #E1E1E1;
  font-size: 14px;
  vertical-align: baseline;
}

.doctor-card .doctor-content .feedback ul li .start-fill {
  color: #F1C40F;
}

.doctor-card .doctor-content .feedback p {
  font-weight: 500;
  font-size: 12px;
  color: #767676;
}

.doctor-card .doctor-content .doctor-info {
  margin: 10px 0;
}

.doctor-card .doctor-content .doctor-info ul {
  margin: 0;
  padding: 0;
}

.doctor-card .doctor-content .doctor-info ul li {
  padding-left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #767676;
  margin-bottom: 10px;
  position: relative;
}

.doctor-card .doctor-content .doctor-info ul li i {
  position: absolute;
  left: 0;
}

.doctor-card .doctor-content .theme-btn {
  border-radius: 5px !important;
  background: transparent !important;
  color: #002A0F !important;
  padding: 6px 10px 6px 25px !important;
} */

/* our Courses */
.ourcourses-section {
  padding: 60px 0;
  background: #A1B8CD;
}

.ourcourses-section::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 0;
  background-image: url(/images/icon/flower-2.svg);
  background-position: center;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  z-index: 0;
  -webkit-animation: moveUptop 1.5s infinite alternate;
  animation: moveUptop 1.5s infinite alternate;
}

.ourcourses-section .courses-head-img {
  align-items: center;
  margin-bottom: 30px;
}

.ourcourses-section .courses-head-img img {
  width: 80%;
  height: auto;
  display: block;
  margin: auto;
  -webkit-animation: moveRightLeft 1s infinite alternate;
  animation: moveRightLeft 1s infinite alternate;
}

.ourcourses-section .courses-slider .owl-stage {
  padding: 20px 0;
}

.courses-card {
  width: 100%;
  height: 100%;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #FAFAFD;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  overflow: hidden;
}

.courses-card .courses-image {
  width: 100%;
  height: 200px;
}

.courses-card .courses-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courses-card .courses-content {
  padding: 15px;
}

.courses-card .courses-content h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #333;
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 75px;
}

.courses-card .courses-content h3 {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin: 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 63px;
}

.courses-card .courses-content ul {
  margin: 0;
  padding: 0;
}

.courses-card .courses-content ul,
.courses-card .courses-content ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.courses-card .courses-content ul li {
  color: #767676;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  margin: 5px 0;
}

.courses-card .courses-content .theme-btn {
  background: transparent !important;
  border-radius: 10px !important;
  color: #002A0F !important;
  padding: 8px 10px 8px 20px !important;
  margin-top: 10px !important;
}

/* FAQ */
.faq-section {
  padding: 100px 0;
}

.faq-section::before {
  content: "";
  position: absolute;
  left: -30px;
  top: 20px;
  background-image: url(/images/icon/leaf.svg);
  background-position: center;
  background-size: contain;
  width: 257px;
  height: 232px;
  background-repeat: no-repeat;
  z-index: -1;
  animation: moveRight 1.5s infinite alternate;
}

.faq-section::after {
  content: "";
  position: absolute;
  right: 30px;
  bottom: 0;
  background-image: url(/images/icon/Sitting.svg);
  background-position: center;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  z-index: -1;
  animation: moveUp 1.5s infinite alternate;
}

.accordion.faq-accordion {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
}

.accordion.faq-accordion .accordion-item {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.accordion.faq-accordion .accordion-item .accordion-button {
  background-color: #fff;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  padding: 15px 60px 15px 15px;
}

.accordion.faq-accordion .accordion-item .accordion-button img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  object-fit: contain;
}

.accordion.faq-accordion .accordion-item .accordion-button:not(.collapsed) {
  color: #002a11;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.accordion.faq-accordion .accordion-item .accordion-button:focus {
  border-color: #fff;
  box-shadow: none;
}

.accordion.faq-accordion .accordion-item .accordion-button::after {
  content: "\ea4e";
  font-family: remixicon !important;
  font-style: normal;
  background-image: unset;
  font-size: 26px;
  font-weight: 200;
  position: absolute;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
}

.accordion.faq-accordion .accordion-item .accordion-button:not(.collapsed)::after {
  content: "\ea78";
  transform: rotate(0);
}

/* Testimonial */
/* .testimonial-section {
  padding: 100px 0;
}

.testimonial-section .testimonial-content h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-transform: capitalize;
  color: #002A0F;
}

.testimonial-section .testimonial-content p {
  color: #333333;
}

.testimonial-slider .testimonial-card {
  background-color: #FFF5E7;
  border-radius: 10px;
  padding: 25px 15px;
  position: relative;
}

.testimonial-slider .testimonial-card .testimonial-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  position: relative;
}

.testimonial-slider .testimonial-card .testimonial-header .user-image img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 100%;
}

.testimonial-slider .testimonial-card .testimonial-header .user-name h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.testimonial-slider .testimonial-card .testimonial-header .user-name h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #777777;
}

.testimonial-slider .testimonial-card p {
  font-weight: 400;
  color: #555555;
}

.testimonial-slider .testimonial-card span {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 60px;
  line-height: 1;
  color: #FBE0C5;
  z-index: 0;
} */

/* learn reiki */
.learnreiki-section {
  padding: 100px 0;
  position: relative;
}

.learnreiki-section::after {
  content: " ";
  background: url(/images/icon/flower.svg);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  -webkit-animation: moveUp 1.5s infinite alternate;
  animation: moveUp 1.5s infinite alternate;

}

.learnreiki-section .middle-box {
  width: 100%;
  background-image: url(/images/learn-reiki-bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 30px;
  border-radius: 20px;
}

.learnreiki-section .middle-box .inner-content {
  text-align: center;
}

.learnreiki-section .middle-box .inner-content h3 {
  text-transform: capitalize;
  color: #FBC42A;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
}

.learnreiki-section .middle-box .inner-content p {
  color: #fff;
}

.learnreiki-section .middle-box .inner-content .theme-btn {
  padding: 8px 15px 8px 30px;
  margin-top: 15px;
}

.learnreiki-section .middle-box .inner-content .theme-btn:hover {
  border-color: #FBC42A !important;
  color: #FBC42A !important;
}

.learnreiki-section .middle-box .yoga-image {
  width: 100%;
  position: relative;
  text-align: center;
}

.learnreiki-section .middle-box .yoga-image img {
  width: 300px;
  max-width: 100%;
  height: auto;
}

/* blog */
/* .blog-section {
  padding: 100px 0;
}

.blog-slider .owl-stage {
  padding: 15px 0;
}

.blog-slider .item {
  padding: 0 8px;
}

.card-blog {
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.card-blog .blog-image {
  width: 100%;
  height: 200px;
}

.card-blog .blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.card-blog .blog-content {
  position: relative;
  padding: 25px 15px;
}

.card-blog .blog-content h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}

.card-blog .blog-content .blog-date {
  background: #002A0F;
  border-radius: 5px;
  padding: 6px 12px;
  display: inline-block;
  position: absolute;
  right: 15px;
  top: -15px;
  color: #ddd;
  font-size: 12px;
  font-weight: 600;
}

.card-blog .blog-content .theme-btn {
  margin-top: 15px !important;
  background: rgba(245, 216, 35, 0.2) !important;
  color: #002A0F !important;
  border-color: rgba(245, 216, 35, 0.2) !important;
  padding: 10px 25px !important;
} */

/* --------------- End Home Page Css --------------------- */
.tabcontent-welcome > .active{
  display: block;
}
.tabcontent-customer > .active{
  display: block;
}

/* Start Moving Animation */
/* img.vert-move {
  -webkit-animation: moveRightLeft 1.5s infinite  alternate;
  animation: moveRightLeft 1.5s infinite  alternate;
} */

@-webkit-keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

/* End Moving Animation */

/* Responsive Home */

@media(max-width:1200px){
.home-banner .banner-content {  
  padding-right: 0px;
}
.slider-searchbar {
  width: 100%;
}
}
@media (max-width:991px) {
  .home-banner .banner-content {
    padding-right: 0;
  }

  .slider-searchbar {
    width: 100%;
  }

  .home-banner .banner-content h1 {
    font-size: 32px;
    line-height: 50px;
  }

  .welcome-healing .tabing-welcome .nav-tabs .nav-link {
    width: 200px;
    padding: 8px 25px;
  }

  .welcome-healing .tabcontent-welcome .welecome-tab-image img {
    max-width: 500px;
    margin: auto;
    display: block;
  }

  /* bringfor-your */
  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head {
    flex-wrap: wrap;
    justify-content: center;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content {
    width: 100%;
    text-align: center;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content h6 br {
    display: none;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .middle-image {
    max-width: 400px;
    margin: 0 auto 10px;
  }

  /* action section */
  .learnreiki-section .middle-box .inner-content {
    padding: 30px 20px;
  }

  .learnreiki-section .middle-box .yoga-image {
    height: 100%;
  }

  .learnreiki-section .middle-box .yoga-image img {
    height: 100%;
  }
  
}

@media (max-width: 767px) {

  /* bringfor-your */

  .bringfor-your {
    padding: 30px 0 0;
  }

  .bringfor-your .tabing-customer {
    overflow: auto;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }

  .bringfor-your .tabing-customer .nav-tabs {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .icon {
    width: 55px;
    height: 55px;
    padding: 10px;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head {
    gap: 10px;
    margin-bottom: 0px;
    width: 100%;
    flex-wrap: nowrap;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content {
    width: calc(100% - 55px);
    text-align: left;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content h6 {
    line-height: 1.7;
    font-size: 14px;
    font-weight: 500;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content h6 br {
    display: none;
  }

  .bringfor-your .tab-content .tab-pane .topSectionHeading:first-child .col-md-4:last-child {
    margin-bottom: 20px;
  }

  .bringfor-your .tab-content .tab-pane .topSectionHeading:first-child .forcustomer-content {
    margin-bottom: 20px;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content {
    margin-bottom: 30px;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-bottom h4 {
    font-weight: 700;
    font-size: 18px;
  }

  /* our courses */
  .ourcourses-section .courses-head-img img {
    display: none;
  }

  /* faq */
  .faq-section {
    padding: 50px 0 150px;
    margin-bottom: 10px;
  }

  .faq-section .faq-accordion {
    padding-bottom: 20px;
  }

  .faq-section .faq-accordion>div:last-child {
    margin-top: 20px;
  }

  /* action section */
  .learnreiki-section {
    padding: 50px 0;
  }

  .learnreiki-section .middle-box {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .learnreiki-section .middle-box .yoga-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    z-index: -1;
    opacity: 0.2;
  }

  .learnreiki-section .middle-box .yoga-image img {
    height: 100%;
  }

  /* blog page */
  .section-banner {
    padding: 50px 0;
    min-height: auto;
  }

  .section-banner .inner-content .banner-title {
    font-size: 32px;
  }
  .welcome-healing {
    padding: 40px 0 60px;
  }
  .welcome-healing .tabing-welcome {
    overflow: auto;
    padding-bottom: 15px;
  }

  .welcome-healing .tabing-welcome .nav-tabs {
    flex-wrap: nowrap;
    width: 100%;
    column-gap: 5px;
    border-radius: 10px;
    padding: 0;
    background: transparent;
    border-radius: 5px;
  }

  .welcome-healing .tabcontent-welcome .inner-content ul.HelpSolveIcon li {
    font-size: 14px;
    width: 100%;
    margin-bottom: 14px;
    column-gap: 8px;
    font-weight: 600;
    color: #000;
  }

  .welcome-healing .tabcontent-welcome .inner-content ul.HelpSolveIcon li .iconImg {
    width: 35px;
    height: 35px;
    padding: 6px;
  }

  .welcome-healing .tabing-welcome .nav-tabs .nav-link {
    display: grid;
    justify-items: center;
    padding: 8px 10px;
    gap: 5px;
    font-size: 12px;
    min-width: 120px;
    width: fit-content;
    border-radius: 5px;
    background-color: #ebebeb;

  }

  .welcome-healing .tabing-welcome .nav-tabs .nav-link img {
    width: 24px;
    height: 24px;
  }

  .welcome-healing::after {
    opacity: 1;
    height: 75px;
    height: 75px;
  }

  .welcome-healing .tabcontent-welcome .inner-content::before {
    width: 80px;
    height: 80px;
    top: -40px;
  }

  .welcome-healing .tabcontent-welcome .inner-content h3 {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 1.5;
  }

  .welcome-healing .tabcontent-welcome .inner-content p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
  }

  .welcome-healing .tabcontent-welcome .inner-content ul {
    column-count: 1;
  }

  .welcome-healing .tabcontent-welcome .welecome-tab-image{
    padding-left: 0;
    margin-top: 20px;
  }
  .welcome-healing .tabcontent-welcome .welecome-tab-image img {
    max-width: 75%;
    margin: 20px auto 30;
    display: block;
  }
  /* <-What is Energy Healing-and-> */
  
  /* <-What Zenki Brings For You-> */
  .bringfor-your .tabing-customer .nav-tabs .nav-link {
    width: max-content;
    padding: 10px 25px;
  }
  .bringfor-your .tabing-customer .nav-tabs .nav-link{
    min-width: 170px;
    width: fit-content;
    padding: 8px 10px;
  }
  .home-banner {
    padding: 20px 0 70px;
    margin-top: 55px;
    height: calc(100dvh - 100px);
  }

  .home-banner .slider-image {
    max-width: 350px;
    margin-bottom: 20px;
  }

  .home-banner .banner-content h1 {
    font-size: 24px;
    line-height: 1.5;
    margin-top: 15px;
  }

  .home-banner .banner-content p {
    font-size: 14px;
    line-height: 2;
    font-weight: 400;
  }
 
}

@media (max-width: 575px) {

  /* home banner */
  .slider-searchbar .theme-btn {
    padding: 5px !important;
  }

  .slider-searchbar .theme-btn span {
    display: none;
  }

  .slider-searchbar .input-group {
    padding-right: 0;
    margin-right: 0;
  }

  .home-banner .banner-content h1 br {
    display: none;
  }
}
.slider-searchbar button.modalitiesdropdown .close-btn{
  margin-right: 16px !important;  
}
@media(max-width:568px){
  .slider-searchbar button.modalitiesdropdown{  
    position: relative;
  }
  .slider-searchbar button.modalitiesdropdown .close-btn{
    right: 0 !important;
    margin-right: 0 !important;  
  }
}
.home-whychoose{
  top: 100px;
}